<template>
    <layout>
        <v-row 
            align="center"
            justify="center"
        > 
            <v-card                
                width="500"
            >  
                <v-card-title :class="cardTitleClass">
                    FAMILY DATA
                </v-card-title>
            </v-card>
        </v-row>

        <v-row
            align="center"
            justify="center"
            class="mt-6"
            v-for="item in livingStatus"
            :key="item.model"
        >
            <v-card
                class="px-6" 
                width="500"
            >        
                <v-row 
                    align="center"
                    justify="center"                    
                >
                    <v-col
                        cols="12"                
                    >                        
                        <v-toolbar-title class="body-2 pl-0 text-wrap">
                            Please indicate which parent(s) / guardian the student lives with
                         </v-toolbar-title>      
                        <v-row>
                            <v-autocomplete
                                v-model="studentRecord['living_status']"
                                :items="livingStatusOptions" 
                                chips                            
                                autocomplete="none"
                                @input="validate(livingStatus,'living_status')"
                                :menu-props="{ top:false, offsetY: false}"                         
                            >
                                <template v-slot:append>
                                    <v-fade-transition>
                                        <v-progress-circular
                                            v-if="item.save"
                                            size="16"
                                            width="3"
                                            color="info"
                                            indeterminate
                                        ></v-progress-circular>
                                    </v-fade-transition>

                                    <v-fade-transition>    
                                        <v-icon
                                            v-if="item.saveSuccess"
                                            small
                                            color="info"                                        
                                        >
                                            mdi-check-all
                                        </v-icon>
                                    </v-fade-transition>

                                    <v-fade-transition>    
                                        <v-icon
                                            v-if="item.saveError"
                                            small
                                            color="red"                                        
                                        >
                                            mdi-alert-circle
                                        </v-icon>
                                    </v-fade-transition>
                                </template>
                            </v-autocomplete>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </v-row>
        
        <!-- Parent Select Options -->
        <v-row
            align="center"
            justify="center"
            v-if="parentSelect"
            
        >
            <v-card
                class="pa-6 mt-4" 
                width="500"
            >        
                <v-row 
                    align="center"
                    justify="center"                    
                >
                    <v-col
                        cols="12"                
                    >                        
                        <v-toolbar-title class="body-2 pl-0 text-wrap">
                            Please select Parents and or Guardian to enter information
                         </v-toolbar-title>      
                        <v-row>
                            <v-col cols="12" sm="4" md="4">
                                <v-checkbox
                                    v-model="visible.Mother"
                                    label="Mother"
                                    color="primary"
                                ></v-checkbox>
                                <v-checkbox
                                    v-model="visible.Father"
                                    label="Father"
                                    color="primary"
                                ></v-checkbox>
                                <v-checkbox
                                    v-model="visible.Guardian"
                                    label="Guardian"
                                    color="primary"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </v-row>
        
        <!-- Parent / Guardian Informtion -->
        <v-row 
            align="center"
            justify="center"
            v-for="item in familyOptions"
            :key="item.option"
        > 
            <v-card
                class="mt-4"
                width="500"
                v-if="visibleSection(item.option)"                
            >
                <v-card-title :class="cardTitleClass">
                    {{ item.option + "'s Information"}}
                </v-card-title>
                
                 <v-row
                    class="px-4"  
                    align="center"
                    justify="center"
                    v-for="field in item.fields"
                    :key="field.label"
                >
                    <v-col
                        cols="12"                        
                        
                    >                       
                        <v-text-field
                            v-if="field.required && field.type == 'phone-number'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validate(item.fields, field.model)"
                            @keypress="numberOnly"
                            :prepend-icon="field.icon"
                            placeholder=" "
                            hint="Enter 7 digits only"
                            persistent-hint
                            prefix="1868"
                            maxlength="7"                                                       
                            :rules="[
                                ()=> !!studentRecord[field.model] || 'This field is required.',
                                () => !isNaN(studentRecord[field.model]) || 'Only Numbers Allowed',
                                () => studentRecord[field.model] && studentRecord[field.model].length == 7 || '7 Digits Needed'
                            ]"
                            autocomplete="none" 
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>

                        <v-text-field
                            v-else-if="field.type == 'phone-number'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validate(item.fields, field.model)"
                            @keypress="numberOnly"
                            :prepend-icon="field.icon"
                            placeholder=" "
                            hint="Enter 7 digits only"
                            persistent-hint
                            prefix="1868"
                            maxlength="7"
                            :rules="[ 
                                () => !isNaN(studentRecord[field.model]) || 'Only Numbers Allowed',
                                () => studentRecord[field.model] && studentRecord[field.model].length == 7 || '7 Digits Needed'
                            ]"
                            autocomplete="none"
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>

                        <v-autocomplete
                            v-else-if="field.type == 'select' && field.required"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            :items="field.items"                        
                            @change="validate(item.fields, field.model)"
                            chips                            
                            :rules="[()=> !!studentRecord[field.model] || 'This field is required.']"
                            autocomplete="none"                            
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-autocomplete>

                        <v-autocomplete
                            v-else-if="field.type == 'select'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            :items="field.items"                        
                            @change="validate(item.fields, field.model)"
                            chips                                                     
                            autocomplete="none"                          
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-autocomplete>

                        <v-text-field
                            v-else-if="field.required && field.type == 'email'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validate(item.fields, field.model)"
                            :rules="[
                                () => !!studentRecord[field.model] || 'This field is required.', 
                                () => regex.test(studentRecord[field.model]) || 'Invalid e-mail' 
                            ]"                             
                            autocomplete="none"
                            counter
                            :maxlength="field.maxlength"
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>

                        <v-text-field
                            v-else-if="field.required"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validate(item.fields, field.model)"
                            :rules="[()=> !!studentRecord[field.model] || 'This field is required.']"                            
                            autocomplete="none"
                            counter
                            :maxlength="field.maxlength"
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>

                        <v-text-field
                            v-else-if="!field.required && field.type == 'email'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validate(item.fields, field.model)"
                            :rules="[
                                () => !!studentRecord[field.model] || 'This field is required.', 
                                () => regex.test(studentRecord[field.model]) || 'Invalid e-mail' 
                            ]"                            
                            autocomplete="none"
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field> 

                        <v-text-field
                            v-else
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validate(item.fields, field.model)"
                            autocomplete="none"
                            counter
                            :maxlength="field.maxlength"
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>                        

                    </v-col>
                   
                </v-row>
            </v-card>            
        </v-row>

        <v-row
            align="center"
            justify="center"
            v-if="section2"
        >
            <v-card
                class="mt-4" 
                width="500"
            >
                <v-card-title :class="cardTitleClass">
                    Family Demographics
                </v-card-title>
                
                <v-row 
                    align="center"
                    justify="center"
                    v-for="field in familyDemographicFields"
                    :key="field.label"
                    class="px-6" 
                >
                    <v-col
                        cols="12"                        
                    >                          
                        <v-text-field
                            v-if="field.required && field.type == 'number'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            :placeholder="field.placeholder"
                            @blur="validate(otherFields, field.model)"
                            :rules="[
                                ()=> !!studentRecord[field.model] || 'This field is required.',
                                () => !isNaN(studentRecord[field.model]) || 'Only Numbers Allowed',                                
                            ]"
                            maxlength="2"
                            hint="Enter number only"
                            persistent-hint
                            autocomplete="none"
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>

                        <v-text-field
                            v-if="field.type == 'number'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            :placeholder="field.placeholder"
                            @blur="validate(otherFields, field.model)"
                            :rules="[
                                ()=> !!studentRecord[field.model] || 'This field is required.',
                                () => !isNaN(studentRecord[field.model]) || 'Only Numbers Allowed',                                
                            ]"
                            maxlength="2"
                            hint="Enter number only"
                            persistent-hint
                            autocomplete="none"
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>

                    </v-col>
                </v-row>
            </v-card>            
        </v-row>

        <v-row
            align="center"
            justify="center"
            v-if="section2"
        >
            <v-card
                class="mt-4" 
                width="500"
            >
                <v-card-title :class="cardTitleClass">
                    Emergency Contact
                </v-card-title>                
                <v-row 
                    class="px-6" 
                    align="center"
                    justify="center"
                    v-for="field in emergencyContactFields"
                    :key="field.label"
                >
                    <v-col
                        cols="12"                        
                    >
                        <v-text-field
                            v-if="field.type == 'phone-number' && field.required"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validate(otherFields, field.model)"
                            @keypress="numberOnly"
                            :prepend-icon="field.icon"
                            placeholder=" "
                            hint="Enter 7 digits only"
                            persistent-hint
                            maxlength="7" 
                            prefix="1868"
                            :rules="[
                                ()=> !!studentRecord[field.model] || 'This field is required.',
                                () => !isNaN(studentRecord[field.model]) || 'Only Numbers Allowed',
                                () => studentRecord[field.model] && studentRecord[field.model].length == 7 || '7 Digits Needed'
                            ]"
                            autocomplete="none" 
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>

                        <v-text-field
                            v-else-if="field.type == 'phone-number'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validate(otherFields, field.model)"
                            @keypress="numberOnly"
                            :prepend-icon="field.icon"
                            placeholder=" "
                            hint="Enter 7 digits only"
                            persistent-hint
                            maxlength="7" 
                            prefix="1868"
                            :rules="[ 
                                () => !isNaN(studentRecord[field.model]) || 'Only Numbers Allowed',
                                () => studentRecord[field.model] && studentRecord[field.model].length == 7 || '7 Digits Needed' 
                            ]"
                            autocomplete="none"
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field> 
                        
                        <v-text-field
                            v-else-if="field.required"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            :placeholder="field.placeholder"
                            @blur="validate(otherFields, field.model)"
                            :rules="[()=> !!studentRecord[field.model] || 'This field is required.']"
                            autocomplete="none"
                            counter
                            :maxlength="field.maxlength"
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>

                        <v-text-field
                            v-else
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            :placeholder="field.placeholder"
                            @blur="validate(otherFields, field.model)"
                            autocomplete="none"
                            counter
                            :maxlength="field.maxlength"                           
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card>
        </v-row>

    </layout>    
</template>

<script>
import Layout from './layouts/Registration';
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'FamilyData',
    components: {
        Layout,
    },
    data () {
        return {
            parentSelect: false,
            livingStatusOptions: ['Both Parents', 'Father Only', 'Mother Only', 'Guardian', 'Other'],            
            livingStatus: [
                {
                    label: 'Living Status', 
                    type: 'select', 
                    model: 'living_status',
                    items: [
                        {text: 'Both Parents'}, 
                        {text: 'Father Only'}, 
                        {text: 'Mother Only'}, 
                        {text: 'Guardian'}, 
                        {text: 'Other'}
                    ],
                    required: false,
                    save: false,
                    saveSuccess: false,
                    saveError: false,
                }
            ],
            familyOptions: [
                { 
                    option: 'Mother', 
                    visible: true,
                    fields: [
                        {
                            label: 'Name', 
                            type: 'text', 
                            model: 'mother_name', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                            maxlength: 35,
                        },                        
                        {
                            label: 'Maritial Status', 
                            type: 'select', 
                            items: ['Married', 'Single', 'Divorced', 'Widowed'],
                            model: 'mother_marital_status',
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: 'Address', 
                            type: 'text', 
                            model: 'mother_home_address', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                            maxlength: 255,
                        },
                        {
                            label: 'Occupation', 
                            type: 'text', 
                            model: 'mother_occupation', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                            maxlength: 35,
                        },
                        {
                            label: 'Employer/Company', 
                            type: 'text', 
                            model: 'mother_business_place', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                            maxlength: 50,
                        },
                        {
                            label: 'Work Address', 
                            type: 'text', 
                            model: 'mother_business_address', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                            maxlength: 50,
                        },
                        {
                            label: 'Phone (Home)', 
                            type: 'phone-number', 
                            model: 'mother_phone_home', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: 'Phone (Work)', 
                            type: 'phone-number', 
                            model: 'mother_business_phone', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: 'Phone (Mobile)', 
                            type: 'phone-number', 
                            model: 'mobile_phone_mother', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: 'Email Address', 
                            type: 'email', 
                            model: 'email_mother', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                            maxlength: 50,
                        },
                        {
                            label: 'ID Card #', 
                            type: 'text', 
                            model: 'id_card_mother', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                            maxlength: 20,
                        },
                    ]
                },
                { 
                    option: 'Father', 
                    visible: true,
                    fields: [
                        {
                            label: 'Name', 
                            type: 'text', 
                            model: 'father_name', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                            maxlength: 35,
                        },                        
                        {
                            label: 'Maritial Status', 
                            type: 'select', 
                            items: ['Married', 'Single', 'Divorced', 'Widowed'],
                            model: 'father_marital_status',
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: 'Address', 
                            type: 'text', 
                            model: 'father_home_address', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                            maxlength: 255,
                        },
                        {
                            label: 'Occupation', 
                            type: 'text', 
                            model: 'father_occupation', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                            maxlength: 35,
                        },
                        {
                            label: 'Employer/Company', 
                            type: 'text', 
                            model: 'father_business_place', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                            maxlength: 50,
                        },
                        {
                            label: 'Work Address', 
                            type: 'text', 
                            model: 'father_business_address', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                            maxlength: 50,
                        },
                        {
                            label: 'Phone (Home)', 
                            type: 'phone-number', 
                            model: 'father_phone_home', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: 'Phone (Work)', 
                            type: 'phone-number', 
                            model: 'father_business_phone', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: 'Phone (Mobile)', 
                            type: 'phone-number', 
                            model: 'mobile_phone_father', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: 'Email Address', 
                            type: 'email', 
                            model: 'email_father', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                            maxlength: 50,
                        },
                        {
                            label: 'ID Card #', 
                            type: 'text', 
                            model: 'id_card_father', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                            maxlength: 20,
                        },
                    ] 
                },
                { 
                    option: 'Guardian', 
                    visible: true,
                    fields: [
                        {
                            label: 'Name', 
                            type: 'text', 
                            model: 'guardian_name', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                            maxlength: 30,
                        },                        
                        {
                            label: 'Maritial Status', 
                            type: 'select', 
                            items: ['Married', 'Single', 'Divorced', 'Widowed'],
                            model: 'guardian_marital_status',
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: 'Address', 
                            type: 'text', 
                            model: 'home_address_guardian', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                            maxlength: 255,
                        },
                        {
                            label: 'Occupation', 
                            type: 'text', 
                            model: 'guardian_occupation', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                            maxlength: 50,
                        },
                        {
                            label: 'Employer/Company', 
                            type: 'text', 
                            model: 'guardian_business_place', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                            maxlength: 50,
                        },
                        {
                            label: 'Work Address', 
                            type: 'text', 
                            model: 'guardian_business_address', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                            maxlength: 50,
                        },
                        {
                            label: 'Phone (Home)', 
                            type: 'phone-number', 
                            model: 'home_phone_guardian', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: 'Phone (Work)', 
                            type: 'phone-number', 
                            model: 'business_phone_guardian', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: 'Phone (Mobile)', 
                            type: 'phone-number', 
                            model: 'mobile_phone_guardian', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                        },
                        {
                            label: 'Email Address', 
                            type: 'email', 
                            model: 'email_guardian', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                            maxlength: 50,
                        },
                        {
                            label: 'ID Card #', 
                            type: 'text', 
                            model: 'id_card_guardian', 
                            required: false,
                            save: false,
                            saveSuccess: false,
                            saveError: false,
                            maxlength: 20,
                        },
                    ] 
                },                
            ],            
            otherFields: [
                {
                    label: 'Number of Children in Family',
                    type: 'number', 
                    model: 'no_in_family', 
                    required: false,
                    category: 'family_demographic',
                    save: false,
                    saveSuccess: false,
                    saveError: false,
                },            
                {
                    label: 'Number of Children at Home', 
                    type: 'number', 
                    model: 'no_at_home',
                    required: false,
                    category: 'family_demographic',
                    save: false,
                    saveSuccess: false,
                    saveError: false,
                },            
                {
                    label: 'Place in Family', 
                    type: 'number', 
                    model: 'place_in_family',
                    required: false,
                    category: 'family_demographic',
                    save: false,
                    saveSuccess: false,
                    saveError: false,
                },            
                {
                    label: 'Emergency Contact / Address', 
                    type: 'text', 
                    model: 'emergency_contact',
                    placeholder: 'Enter name of an emergency contact',
                    required: false,
                    category: 'emergency_contact',
                    save: false,
                    saveSuccess: false,
                    saveError: false,
                    maxlength: 50,
                },            
                {
                    label: 'Telephone Number', 
                    type: 'phone-number', 
                    model: 'emergency_home_phone',
                    required: false,
                    category: 'emergency_contact',
                    save: false,
                    saveSuccess: false,
                    saveError: false,
                },            
                {
                    label: 'Work Place / Telephone Number', 
                    type: 'text', 
                    model: 'relative_in_school',
                    required: false,
                    category: 'emergency_contact',
                    save: false,
                    saveSuccess: false,
                    saveError: false,
                    maxlength: 50,
                },            
                {
                    label: 'Relation to Child', 
                    type: 'text', 
                    model: 'relation_to_child',
                    required: false,
                    category: 'emergency_contact',
                    save: false,
                    saveSuccess: false,
                    saveError: false,
                    maxlength: 50,
                },            
                // {
                //     label: 'Workplace Number (optional)', 
                //     type: 'phone-number', 
                //     model: 'emergency_work_phone',
                //     required: false,
                //     category: 'emergency_contact',
                // }, 
            ],
            studentRecord: {},
            parents: ['Mother', 'Father', 'Guardian'],
            parentsSelected: [],
            section2: true,
            visible:{
                Mother: true,
                Father: true,
                Guardian: true,
            },                                      
        }        
    },

    mounted: function () {
        this.setStudentRecord();
        this.setParentsSelected();
        this.familyDataFields();           
    },

    computed: {
        ...mapGetters({
            getStudentRecord: 'auth/getStudentRecord',
            getFamilyDataMounted: 'form/getFamilyDataMounted',
        }),

        visibleFamily(){
            return this.familyOptions.filter( field => field.visible == true);
        },

        regex(){
            return new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) 
        },

        emergencyContactFields(){
            return this.otherFields.filter( field => field.category == 'emergency_contact');
        },

        familyDemographicFields(){
            return this.otherFields.filter( field => field.category == 'family_demographic');
        },

        cardTitleClass(){
            return "white--text pl-4 primary";
        }         
    },  
    
    methods: {
        async setStudentRecord(){
            if(!this.getStudentRecord){
                await this.getStudentData();
            }
            this.studentRecord = {...this.getStudentRecord}
        },
        ...mapMutations({
            updateStudentRecord: 'auth/setStudentRecord',
            setFamilyDataValidated: 'form/setFamilyDataValidated',
            setValidationErrors: 'form/setValidationErrors',
            setFamilyDataFields: 'form/setFamilyDataFields',
            setFamilyDemographicFields: 'form/setFamilyDemographicFields'          
        }),
        ...mapActions({
            saveRecord: 'auth/saveRecord',
            getStudentData: 'auth/getStudent', 
        }),

        async updateStore(array, model){
            console.log('updating store');
            //console.log(this.studentRecord);
            this.updateStudentRecord(this.studentRecord);
            try {
                let response = await this.saveRecord();
                console.log(response);
                this.setSaveStatus(array, model, 'saved');  
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.setSaveStatus(array, model, 'error');  
            }            
        },

        setVisibleParent(){
            let noParentSelected = true;            
            this.familyOptions.forEach(familyOption => {
                familyOption.visible = this.visible[familyOption.option];
                if(this.visible[familyOption.option]) noParentSelected = false;
            });
            console.log(this.familyOptions);
            if(!noParentSelected) this.section2 = true;
            else this.section2 = false;
            this.familyDataFields();  
        },

        visibleSection(section){
            switch(section){
                case "Mother":
                    return this.visible.Mother;
                case "Father":
                    return this.visible.Father;
                case "Guardian":
                    return this.visible.Guardian;
            }            
        },

        setParentsSelected(){
            if(this.studentRecord.mother_name) {
                this.parentsSelected.push('Mother');
                this.visible.Mother = true;
                this.familyOptions.forEach((parent, index) => {
                    if(parent.option == 'Mother'){
                        this.familyOptions[index].visible = true;                        
                    }
                })
            }
            if(this.studentRecord.father_name) {
                this.parentsSelected.push('Father');
                this.visible.Father = true;
                this.familyOptions.forEach((parent, index) => {
                    if(parent.option == 'Father'){
                        this.familyOptions[index].visible = true;                       
                    }
                })
            }
            if(this.studentRecord.guardian_name) {
                this.parentsSelected.push('Guardian');
                this.visible.Guardian = true;
                this.familyOptions.forEach((parent, index) => {
                    if(parent.option == 'Guardian'){
                        this.familyOptions[index].visible = true;                        
                    }
                })
            }
        },

        livingStatusSelected(){
            //console.log(this.studentRecord.living_status);
            switch(this.studentRecord.living_status){
                case 'Both Parents':                    
                    this.visible.Father = true;
                    this.visible.Mother = true;
                    this.visible.Guardian = false;
                    break;
                case 'Father Only':                    
                    this.visible.Father = true;
                    this.visible.Mother = false;
                    this.visible.Guardian = false;
                    break;
                case 'Mother Only':                    
                    this.visible.Father = false;
                    this.visible.Mother = true;
                    this.visible.Guardian = false;
                    break;
                case 'Guardian':                    
                    this.visible.Father = false;
                    this.visible.Mother = false;
                    this.visible.Guardian = true;
                    break;
                case 'Other':                    
                    this.visible.Father = false;
                    this.visible.Mother = false;
                    this.visible.Guardian = false;
                    break;
            }
            //this.validate();
        },

        validate (array, model) {           
            this.setSaveStatus(array, model);            
            this.familyDataFields();
            this.updateStore(array, model);
        },
        
        familyDataFields () {
            this.setFamilyDataFields([...this.familyOptions]);
            this.setFamilyDemographicFields([...this.otherFields]);
        },

        setSaveStatus (array, model, status = 'saving') {
            //console.log(index);
            //console.log(this.dataFields[index-1]);
            for(const field of array) {                
                if(field.model == model){                    
                    switch (status){
                        case 'saving':                            
                            field.saveSuccess = false;
                            field.saveError = false;                            
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    field.save = true;                                                                      
                                    resolve(status);
                                }, 600)
                            })                            
                            
                        case 'saved':
                            field.save = false;
                            field.saveError = false;
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    field.saveSuccess = true; 
                                    resolve(status);
                                }, 600)
                            })
                            
                        case 'error':
                            field.save = false;
                            field.saveSuccess = false;
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    field.saveError = true;
                                    resolve(status);
                                }, 600)
                            })
                            
                        default:
                            console.log('no match found');
                            return new Promise(resolve => {
                                resolve('no match');
                            });             
                    }                    
                }
            }
                    
        },

        numberOnly($event){            
            if(!/\d/.test($event.key)) return $event.preventDefault();
        },
               
    }
}
</script>